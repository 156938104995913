import PEOPLE_FOR_REVIEW from "../assets/images/people_for_reviews.png";
import REVIEWER_1 from "../assets/images/reviewer_1.png";
import REVIEWER_2 from "../assets/images/reviewer_2.png";
import REVIEWER_3 from "../assets/images/reviewer_3.png";

import ReviewStars from "./ReviewStars";

export default function Reviews() {
  return (
    <div class="bg-orange-100 py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0 text-left">
          <img src={PEOPLE_FOR_REVIEW} className="w-auto h-10 mb-2" />
          <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            100,000+
          </h2>
          <h2 class="text-3xl tracking-tight text-gray-900 sm:text-2xl">
            personalized meditations created
          </h2>
          <p class="mt-4 text-lg leading-8">
            Our users enjoy this tailored experience every day. Our intelligent
            AI technology learns from your preferences to deliver personalized
            meditation recommendations.
          </p>
        </div>
        <div class="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-orange-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <article class="flex max-w-xl flex-col items-start justify-between text-left">
            <div class="relative mt-2 lg:mt-8 flex items-center gap-x-4">
              <img
                src={REVIEWER_1}
                alt=""
                class="h-10 w-10 rounded-full bg-gray-50"
              />
              <div class="text-sm leading-6">
                <p class="font-semibold text-gray-900">Patricia</p>
                <ReviewStars />
              </div>
            </div>
            <div class="group relative">
              <h3 class="mt-4 text-lg font-semibold leading-6 text-gray-900 ">
                Nice to have
              </h3>
              <p class="mt-3 text-sm leading-6 text-gray-600">
                I've been using this app for a month now, and I love how it
                makes meditation simple and accessible. The guided sessions are
                perfect for a quick mental reset in a hectic day. Definitely
                helps with my anxiety and overall stress levels.
              </p>
            </div>
          </article>
          <article class="flex max-w-xl flex-col items-start justify-between text-left">
            <div class="relative mt-2 lg:mt-8 flex items-center gap-x-4">
              <img
                src={REVIEWER_2}
                alt=""
                class="h-10 w-10 rounded-full bg-gray-50"
              />
              <div class="text-sm leading-6">
                <p class="font-semibold text-gray-900">David</p>
                <ReviewStars />
              </div>
            </div>
            <div class="group relative">
              <h3 class="mt-2 text-lg font-semibold leading-6 text-gray-900 ">
                I've changed
              </h3>
              <p class="mt-3 text-sm leading-6 text-gray-600">
                This app has transformed my mornings. The variety of meditation
                styles and the soothing background music make it a joy to use.
                It's like having a personal meditation coach in your pocket.
                Highly recommend for anyone looking to improve their
                mindfulness.
              </p>
            </div>
          </article>
          <article class="flex max-w-xl flex-col items-start justify-between text-left">
            <div class="relative mt-2 lg:mt-8 flex items-center gap-x-4">
              <img
                src={REVIEWER_3}
                alt=""
                class="h-10 w-10 rounded-full bg-gray-50"
              />
              <div class="text-sm leading-6">
                <p class="font-semibold text-gray-900">Michelle</p>
                <ReviewStars />
              </div>
            </div>
            <div class="group relative">
              <h3 class="mt-2 text-lg font-semibold leading-6 text-gray-900">
                Effective and easy
              </h3>
              <p class="mt-3 text-sm leading-6 text-gray-600">
                Great app for both beginners and experienced meditators. The
                customizable sessions allow me to fit meditation into any part
                of my day, which is fantastic. The tracking features also help
                me stay committed. A must-have for anyone serious about
                meditating regularly.
              </p>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}
