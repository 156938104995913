export default function ReviewStars() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="16"
      viewBox="0 0 72 16"
      fill="none"
    >
      <g clip-path="url(#clip0_400_105)">
        <path
          d="M8.00016 11.5133L12.1202 14L11.0268 9.31334L14.6668 6.16001L9.8735 5.75334L8.00016 1.33334L6.12683 5.75334L1.3335 6.16001L4.9735 9.31334L3.88016 14L8.00016 11.5133Z"
          fill="#C2410C"
        />
      </g>
      <g clip-path="url(#clip1_400_105)">
        <path
          d="M22.0002 11.5133L26.1202 14L25.0268 9.31334L28.6668 6.16001L23.8735 5.75334L22.0002 1.33334L20.1268 5.75334L15.3335 6.16001L18.9735 9.31334L17.8802 14L22.0002 11.5133Z"
          fill="#C2410C"
        />
      </g>
      <g clip-path="url(#clip2_400_105)">
        <path
          d="M36.0002 11.5133L40.1202 14L39.0268 9.31334L42.6668 6.16001L37.8735 5.75334L36.0002 1.33334L34.1268 5.75334L29.3335 6.16001L32.9735 9.31334L31.8802 14L36.0002 11.5133Z"
          fill="#C2410C"
        />
      </g>
      <g clip-path="url(#clip3_400_105)">
        <path
          d="M50.0002 11.5133L54.1202 14L53.0268 9.31334L56.6668 6.16001L51.8735 5.75334L50.0002 1.33334L48.1268 5.75334L43.3335 6.16001L46.9735 9.31334L45.8802 14L50.0002 11.5133Z"
          fill="#C2410C"
        />
      </g>
      <g clip-path="url(#clip4_400_105)">
        <path
          d="M64.0002 11.5133L68.1202 14L67.0268 9.31334L70.6668 6.16001L65.8735 5.75334L64.0002 1.33334L62.1268 5.75334L57.3335 6.16001L60.9735 9.31334L59.8802 14L64.0002 11.5133Z"
          fill="#C2410C"
        />
      </g>
      <defs>
        <clipPath id="clip0_400_105">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_400_105">
          <rect width="16" height="16" fill="white" transform="translate(14)" />
        </clipPath>
        <clipPath id="clip2_400_105">
          <rect width="16" height="16" fill="white" transform="translate(28)" />
        </clipPath>
        <clipPath id="clip3_400_105">
          <rect width="16" height="16" fill="white" transform="translate(42)" />
        </clipPath>
        <clipPath id="clip4_400_105">
          <rect width="16" height="16" fill="white" transform="translate(56)" />
        </clipPath>
      </defs>
    </svg>
  );
}
