import {
  SparklesIcon,
  WrenchScrewdriverIcon,
  ChartBarIcon,
  CircleStackIcon,
} from "@heroicons/react/20/solid";

export default function Features() {
  return (
    <div class="py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:text-center">
          <h2 class="text-base font-semibold leading-7 text-orange-700">
            Features
          </h2>
          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Unique meditation experience
          </p>
          <p class="mt-6 text-lg leading-8 text-gray-600">
            With the help of AI and our personalisation method, Sanctuary will
            deliver new meditations every day.
          </p>
        </div>
        <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl text-left">
          <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            <div class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-orange-500">
                  <SparklesIcon color="white" className="h-6" />
                </div>
                Always unique
              </dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">
                Almost impossible to get the same meditation twice. Be surprised
                every time.
              </dd>
            </div>
            <div class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-orange-500">
                  <CircleStackIcon color="white" className="h-6" />
                </div>
                Large collection
              </dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">
                We cover many types of meditations. We target specific areas
                like stress, sleep, focus, etc.
              </dd>
            </div>
            <div class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-orange-500">
                  <ChartBarIcon color="white" className="h-6" />
                </div>
                Important stats
              </dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">
                Track your mindfulness level and other statistics. Your progress
                is important.
              </dd>
            </div>
            <div class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-orange-500">
                  <WrenchScrewdriverIcon color="white" className="h-6" />
                </div>
                Custom settings
              </dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">
                You will be able to select music, voice and other settings to
                make sanctuary truly yours.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
