export default function Footer() {
  return (
    <footer className="bg-orange-950 text-white p-8">
      <div className="max-w-screen-xl mx-auto flex flex-col items-center">
        {/* <div className="mb-8">
          <a href="/" className="text-white mx-2 hover:text-gray-300">
            Home
          </a>
          <a href="#about" className="text-white mx-2 hover:text-gray-300">
            Features
          </a>
          <a href="#services" className="text-white mx-2 hover:text-gray-300">
            Meditations
          </a>
          <a href="#contact" className="text-white mx-2 hover:text-gray-300">
            About
          </a>
        </div> */}
        <div className="flex flex-row items-center" >
          <a
            href="https://www.youtube.com/@sanctuaryapp"
            className="mx-2"
          >
            <svg width="95" height="21" viewBox="0 0 95 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M29.6625 3.27964C29.3134 1.98813 28.2883 0.973196 26.984 0.627477C24.6223 0.000244328 15.1479 0.000244141 15.1479 0.000244141C15.1479 0.000244141 5.67362 0.000244328 3.3119 0.627477C2.0076 0.973196 0.982598 1.98813 0.633451 3.27964C1.89616e-07 5.61817 0 10.5002 0 10.5002C0 10.5002 1.89616e-07 15.3823 0.633451 17.7208C0.982598 19.0123 2.0076 20.0273 3.3119 20.3729C5.67362 21.0002 15.1479 21.0002 15.1479 21.0002C15.1479 21.0002 24.6223 21.0002 26.984 20.3729C28.2883 20.0273 29.3134 19.0123 29.6625 17.7208C30.2959 15.3823 30.2959 10.5002 30.2959 10.5002C30.2959 10.5002 30.2934 5.61817 29.6625 3.27964Z" fill="#FF0000" />
              <path d="M12.1157 14.9997L19.9865 10.5004L12.1157 6.00113V14.9997Z" fill="white" />
              <path d="M36.6927 13.6538L33.291 1.48944H36.2588L37.4509 7.00365C37.7551 8.36183 37.9771 9.51999 38.1217 10.4781H38.209C38.3088 9.79163 38.5332 8.64088 38.8799 7.02341L40.1143 1.48944H43.0821L39.638 13.6538V19.4891H36.6903V13.6538H36.6927Z" fill="white" />
              <path d="M43.9748 19.1034C43.3763 18.7034 42.9498 18.081 42.6954 17.2365C42.4435 16.392 42.3164 15.2709 42.3164 13.8682V11.9593C42.3164 10.5444 42.461 9.406 42.7503 8.5491C43.0396 7.69222 43.491 7.06498 44.1045 6.67235C44.718 6.27971 45.5235 6.08215 46.5211 6.08215C47.5037 6.08215 48.2893 6.28218 48.8828 6.68223C49.4738 7.08227 49.9078 7.7095 50.1821 8.55898C50.4564 9.41093 50.5936 10.5444 50.5936 11.9593V13.8682C50.5936 15.2709 50.459 16.3969 50.1922 17.2464C49.9253 18.0983 49.4913 18.7207 48.8927 19.1133C48.2942 19.506 47.4812 19.7035 46.4562 19.7035C45.3988 19.7059 44.5734 19.5035 43.9748 19.1034ZM47.3316 17.0439C47.4962 16.6143 47.581 15.9154 47.581 14.9425V10.8457C47.581 9.90235 47.4987 9.21091 47.3316 8.77629C47.1645 8.33921 46.8728 8.1219 46.4537 8.1219C46.0498 8.1219 45.7629 8.33921 45.5984 8.77629C45.4312 9.21338 45.349 9.90235 45.349 10.8457V14.9425C45.349 15.9154 45.4287 16.6167 45.5884 17.0439C45.748 17.4736 46.0347 17.6884 46.4537 17.6884C46.8728 17.6884 47.1645 17.4736 47.3316 17.0439Z" fill="white" />
              <path d="M60.2477 19.4916H57.9084L57.6491 17.8815H57.5843C56.9482 19.0965 55.9956 19.7039 54.7237 19.7039C53.8434 19.7039 53.1925 19.4175 52.7735 18.8471C52.3546 18.2741 52.145 17.3803 52.145 16.1653V6.33944H55.1353V15.9924C55.1353 16.5802 55.2001 16.9974 55.3297 17.2469C55.4594 17.4963 55.6764 17.6222 55.9806 17.6222C56.24 17.6222 56.4894 17.5432 56.7288 17.3852C56.9683 17.2271 57.1428 17.027 57.26 16.7851V6.33698H60.2477V19.4916Z" fill="white" />
              <path d="M68.3702 3.87201H65.4024V19.4911H62.477V3.87201H59.5093V1.49149H68.3702V3.87201Z" fill="white" />
              <path d="M75.5827 19.4916H73.2434L72.984 17.8815H72.9192C72.2833 19.0965 71.3306 19.7039 70.0587 19.7039C69.1783 19.7039 68.5275 19.4175 68.1085 18.8471C67.6895 18.2741 67.48 17.3803 67.48 16.1653V6.33944H70.4702V15.9924C70.4702 16.5802 70.535 16.9974 70.6647 17.2469C70.7944 17.4963 71.0113 17.6222 71.3157 17.6222C71.575 17.6222 71.8244 17.5432 72.0638 17.3852C72.3032 17.2271 72.4778 17.027 72.595 16.7851V6.33698H75.5827V19.4916Z" fill="white" />
              <path d="M85.4785 8.44067C85.2964 7.61095 85.0046 7.01088 84.6006 6.63799C84.1966 6.26512 83.6404 6.07991 82.9322 6.07991C82.3835 6.07991 81.8698 6.23301 81.3934 6.54169C80.9171 6.85037 80.548 7.25288 80.2886 7.75417H80.2662V0.824982H77.3857V19.4889H79.8547L80.1589 18.2443H80.2238C80.4557 18.6888 80.8024 19.0369 81.2638 19.2963C81.7251 19.5531 82.2389 19.6815 82.8025 19.6815C83.8126 19.6815 84.5582 19.2197 85.0346 18.2986C85.5109 17.375 85.7502 15.9353 85.7502 13.9747V11.893C85.7502 10.4236 85.658 9.2704 85.4785 8.44067ZM82.7376 13.8067C82.7376 14.7648 82.6977 15.5156 82.6179 16.0588C82.5382 16.6021 82.4059 16.9899 82.2164 17.217C82.0294 17.4466 81.775 17.5602 81.4582 17.5602C81.2114 17.5602 80.9844 17.5034 80.7749 17.3874C80.5655 17.2738 80.3959 17.1009 80.2662 16.8737V9.40869C80.3659 9.05062 80.5406 8.75923 80.7874 8.52958C81.0318 8.29992 81.3012 8.18632 81.5879 8.18632C81.8923 8.18632 82.1266 8.30486 82.2913 8.53946C82.4583 8.77651 82.573 9.17163 82.6379 9.72971C82.7027 10.2878 82.7352 11.0805 82.7352 12.1102V13.8067H82.7376Z" fill="white" />
              <path d="M89.9921 14.5648C89.9921 15.4093 90.0171 16.0415 90.067 16.4638C90.1168 16.886 90.2216 17.1923 90.3812 17.3874C90.5408 17.58 90.7852 17.6763 91.1169 17.6763C91.5633 17.6763 91.8726 17.5034 92.0371 17.1602C92.2043 16.8169 92.2941 16.2441 92.309 15.444L94.8877 15.5945C94.9027 15.7081 94.9101 15.8662 94.9101 16.0662C94.9101 17.2811 94.5734 18.1899 93.9026 18.79C93.2318 19.3901 92.2816 19.6913 91.0546 19.6913C89.5807 19.6913 88.5482 19.2344 87.9571 18.3183C87.3636 17.4022 87.0693 15.9872 87.0693 14.0709V11.7743C87.0693 9.80129 87.3761 8.35915 87.9896 7.45041C88.6031 6.54166 89.653 6.08728 91.1418 6.08728C92.1668 6.08728 92.9549 6.27249 93.5036 6.64537C94.0522 7.01825 94.4387 7.5961 94.6632 8.38385C94.8877 9.17159 94.9999 10.2581 94.9999 11.6459V13.898H89.9921V14.5648ZM90.3712 8.36656C90.2191 8.55176 90.1194 8.8555 90.067 9.27777C90.0171 9.70005 89.9921 10.3396 89.9921 11.199V12.1423H92.1793V11.199C92.1793 10.3544 92.1494 9.71486 92.0921 9.27777C92.0347 8.84069 91.9299 8.53448 91.7778 8.35421C91.6257 8.17642 91.3912 8.08505 91.0745 8.08505C90.7553 8.08752 90.5209 8.18135 90.3712 8.36656Z" fill="white" />
            </svg>
          </a>
          <a
            href="https://podcasts.apple.com/us/podcast/meditations-by-sanctuary/id1741543248"
            className="mx-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
              <g clip-path="url(#clip0_450_103)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.412999 18.2C0.615999 18.718 1.085 19.446 1.757 19.964C2.142 20.258 2.604 20.545 3.234 20.734C3.906 20.937 4.739 21 5.775 21H15.225C16.261 21 17.087 20.93 17.766 20.734C18.396 20.545 18.858 20.265 19.243 19.964C19.908 19.453 20.384 18.718 20.587 18.2C20.993 17.157 21 15.974 21 15.225V5.775C21 5.026 20.993 3.843 20.587 2.8C20.384 2.282 19.915 1.554 19.243 1.036C18.858 0.742 18.396 0.455 17.766 0.266C17.087 0.07 16.261 0 15.225 0H5.775C4.739 0 3.913 0.07 3.234 0.266C2.604 0.455 2.142 0.735 1.757 1.036C1.092 1.547 0.615999 2.282 0.412999 2.8C-5.23329e-07 3.843 -8.9407e-08 5.033 -8.9407e-08 5.775V15.225C-8.9407e-08 15.974 0.00699947 17.157 0.412999 18.2Z" fill="url(#paint0_linear_450_103)" />
                <path d="M8.70127 12.677C8.72927 12.425 8.81327 12.243 8.98127 12.075C9.29627 11.746 9.84927 11.529 10.5003 11.529C11.1513 11.529 11.7043 11.739 12.0193 12.075C12.1803 12.25 12.2713 12.425 12.2993 12.677C12.3553 13.167 12.3203 13.587 12.2643 14.266C12.2083 14.91 12.1033 15.771 11.9703 16.639C11.8723 17.262 11.7953 17.598 11.7253 17.836C11.6063 18.228 11.1793 18.564 10.5003 18.564C9.82127 18.564 9.38727 18.221 9.27527 17.836C9.20527 17.598 9.12827 17.262 9.03027 16.639C8.89727 15.764 8.79227 14.91 8.73627 14.266C8.67327 13.587 8.64527 13.167 8.70127 12.677Z" fill="white" />
                <path d="M8.77817 9.10698C8.77817 10.059 9.54817 10.829 10.5002 10.829C11.4522 10.829 12.2222 10.059 12.2222 9.10698C12.2222 8.15498 11.4522 7.38498 10.5002 7.38498C9.54817 7.38498 8.77817 8.16198 8.77817 9.10698Z" fill="white" />
                <path d="M10.5211 2.32399C14.5391 2.33799 17.8291 5.59998 17.8851 9.61799C17.9271 12.873 15.8481 15.659 12.9431 16.681C12.8731 16.709 12.8031 16.646 12.8101 16.576C12.8451 16.324 12.8871 16.072 12.9151 15.82C12.9291 15.729 12.9851 15.659 13.0621 15.617C15.3581 14.616 16.9611 12.313 16.9331 9.64599C16.8981 6.14599 14.0421 3.29699 10.5491 3.26899C6.97212 3.24099 4.05312 6.13899 4.05312 9.70899C4.05312 12.348 5.64912 14.616 7.92412 15.617C8.00812 15.652 8.06412 15.729 8.07112 15.82C8.10612 16.072 8.14112 16.324 8.17612 16.576C8.19012 16.653 8.11312 16.709 8.04312 16.681C5.16612 15.673 3.10112 12.929 3.10112 9.70899C3.11512 5.63499 6.44012 2.31699 10.5211 2.32399Z" fill="white" />
                <path d="M10.689 4.77398C13.307 4.87198 15.407 7.03498 15.442 9.65298C15.463 11.375 14.602 12.901 13.279 13.804C13.216 13.846 13.125 13.797 13.125 13.72C13.146 13.419 13.146 13.153 13.132 12.873C13.125 12.782 13.16 12.698 13.23 12.635C14.035 11.879 14.525 10.801 14.497 9.61098C14.441 7.51098 12.754 5.80298 10.654 5.71898C8.37201 5.62798 6.49601 7.46198 6.49601 9.71598C6.49601 10.864 6.98601 11.9 7.76301 12.635C7.82601 12.698 7.86101 12.782 7.86101 12.873C7.84701 13.146 7.85401 13.412 7.86801 13.713C7.87501 13.79 7.78401 13.846 7.71401 13.797C6.41201 12.908 5.55101 11.403 5.55101 9.70898C5.54401 6.92298 7.87501 4.66198 10.689 4.77398Z" fill="white" />
              </g>
              <defs>
                <linearGradient id="paint0_linear_450_103" x1="10.5" y1="0" x2="10.5" y2="21" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#F452FF" />
                  <stop offset="1" stop-color="#832BC1" />
                </linearGradient>
                <clipPath id="clip0_450_103">
                  <rect width="21" height="21" fill="white" transform="matrix(-1 0 0 1 21 0)" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <div className="mt-4">mysanctuaryapp@gmail.com</div>
        <div className="mt-2">© 2024 Sanctuary App. All rights reserved.</div>
      </div>
    </footer>
  );
}
