import "./App.css";
import Features from "./components/Features";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Featured from "./components/Featured";
import ListenAnywhere from "./components/ListenAnywhere";
import Reviews from "./components/Reviews";
import MeditationExamples from "./components/MeditationExamples";
import Footer from "./components/Footer";
import Mood from "./components/Mood";
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from "react-ga4";

const TRACKING_ID = "G-X287PF74PT";
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    // <HelmetProvider>
    <div className="App">
      {/* <Helmet>
          <title>Sanctuary: Meditations & Sleep</title>
          <meta
            name="description"
            content="Find your peace and unlock your utmost potential with Sanctuary—your personalized meditation guide powered by cutting-edge AI."
          />
          <meta
            name="keywords"
            content="ai,sanctuary,balance,focus,mindbody,insight,insomnia,moment,meditation,mindfulness,stress,calm,sleep"
          />
          <meta name="author" content="ourimo" />
          <meta property="og:title" content="Sanctuary: Meditations & Sleep" />
          <meta
            property="og:description"
            content="Find your peace and unlock your utmost potential with Sanctuary—your personalized meditation guide powered by cutting-edge AI."
          />
          <meta property="og:image" content="https://appsanctuary.com/static/media/woman_with_headphones.69e520c1910d28f77546.png" />
          <meta property="og:url" content="https://appsanctuary.com" />
          <meta name="twitter:title" content="Sanctuary: Meditations & Sleep" />
          <meta
            name="twitter:description"
            content="Find your peace and unlock your utmost potential with Sanctuary—your personalized meditation guide powered by cutting-edge AI."
          />
          <meta name="twitter:image" content="https://appsanctuary.com/static/media/woman_with_headphones.69e520c1910d28f77546.png" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet> */}
      <Header />
      <Hero />
      <Featured />
      <MeditationExamples />
      <Mood />
      <Reviews />
      <Features />
      <ListenAnywhere />
      <Footer />
    </div>
    // </HelmetProvider>
  );
}

export default App;
