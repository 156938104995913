import MOOD_1 from "../assets/images/mood_1.jpg";
import MOOD_2 from "../assets/images/mood_2.jpg";
import MOOD_3 from "../assets/images/mood_3.jpg";
import MOOD_4 from "../assets/images/mood_4.jpg";
import MOOD_5 from "../assets/images/mood_5.jpg";

export default function Mood() {
  return (
    <div className="isolate px-6 lg:px-8 lg:pt-4 pb-10 lg:pb-4">
      <div className="mx-auto max-w-6xl py-8 lg:py-12 flex flex-col lg:flex-row items-center">
        <div className="flex-1 flex flex-row items-center mb-12 lg:mb-0 lg:px-16">
          <div className="flex flex-col mr-4">
            <img className="h-auto lg:w-full mb-4 rounded-xl overflow-hidden" src={MOOD_1} />
            <img className="h-auto lg:w-full rounded-xl " src={MOOD_5} />
          </div>
          <div className="flex flex-col">
            <img className="h-auto lg:w-full mb-4 rounded-xl " src={MOOD_2} />
            <img className="h-auto lg:w-full mb-4 rounded-xl " src={MOOD_3} />
            <img className="h-auto lg:w-full rounded-xl " src={MOOD_4} />
          </div>
        </div>
        <div className="flex-1 text-left">
          <div>
            <h2 className="text-2xl sm:text-4xl font-bold tracking-tight text-gray-900 ">
              Better life quality
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Meditation can greatly enhance your life quality. Meditate daily with ease and you'll notice the benefits.
            </p>
            <a
              href="https://apps.apple.com/us/app/sanctuary-meditation-sleep/id6448285306"
              target="_blank"
              rel="noopener noreferrer"
              className="max-w-sm mt-8 rounded-md bg-orange-500 px-12 py-4 text-md font-semibold text-white shadow-sm hover:bg-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="23"
                viewBox="0 0 19 23"
                fill="none"
              >
                <g clip-path="url(#clip0_437_147)">
                  <path
                    d="M18.3955 7.8407C18.2601 7.9442 15.8699 9.2713 15.8699 12.2222C15.8699 15.6354 18.9113 16.8429 19.0023 16.8728C18.9883 16.9464 18.5192 18.5265 17.3988 20.1365C16.3998 21.5533 15.3564 22.9678 13.7692 22.9678C12.1819 22.9678 11.7735 22.0593 9.94115 22.0593C8.15553 22.0593 7.52064 22.9977 6.0688 22.9977C4.61695 22.9977 3.60393 21.6867 2.43919 20.0767C1.09005 18.1861 0 15.249 0 12.4614C0 7.9902 2.95037 5.6189 5.85405 5.6189C7.39693 5.6189 8.68305 6.6171 9.65172 6.6171C10.5737 6.6171 12.0115 5.5591 13.7668 5.5591C14.4321 5.5591 16.8222 5.6189 18.3955 7.8407ZM12.9335 3.6662C13.6595 2.8175 14.173 1.6399 14.173 0.4623C14.173 0.299 14.159 0.1334 14.1286 0C12.9475 0.0437 11.5424 0.7751 10.6951 1.7434C10.0299 2.4886 9.40897 3.6662 9.40897 4.8599C9.40897 5.0393 9.43931 5.2187 9.45332 5.2762C9.52801 5.29 9.64939 5.3061 9.77076 5.3061C10.8305 5.3061 12.1633 4.6069 12.9335 3.6662Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_437_147">
                    <rect width="19" height="23" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="ml-2">Get started for free</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
