import React, { useState, useEffect, useRef } from 'react';
import {
    PlayIcon,
    PauseIcon
} from "@heroicons/react/20/solid";

export default function AudioPlayer({ src }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const audioRef = useRef(null);

    useEffect(() => {
        const audio = audioRef.current;

        const setAudioData = () => {
            setDuration(audio.duration);
            setCurrentTime(audio.currentTime);
        };

        const setAudioTime = () => setCurrentTime(audio.currentTime);

        audio.addEventListener('loadeddata', setAudioData);
        audio.addEventListener('timeupdate', setAudioTime);

        return () => {
            audio.removeEventListener('loadeddata', setAudioData);
            audio.removeEventListener('timeupdate', setAudioTime);
        };
    }, []);

    const togglePlayPause = () => {
        const prevValue = isPlaying;
        setIsPlaying(!prevValue);
        if (!prevValue) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    };

    const handleSeek = (event) => {
        const seekTo = Number(event.target.value);
        audioRef.current.currentTime = seekTo;
        setCurrentTime(seekTo);
    };

    const formatTime = (time) => {
        return `${Math.floor(time / 60)}:${("0" + Math.floor(time % 60)).slice(-2)}`;
    };

    return (
        <div className="max-w-md mx-auto pl-5 pr-6 py-4">
            <audio ref={audioRef} src={src} preload="metadata" className="hidden" />
            <div className="flex items-center gap-4">
                <button onClick={togglePlayPause} className="p-7 rounded-full bg-orange-500 text-white">
                    {isPlaying ? <PauseIcon className="size-10 text-white-500" /> : <PlayIcon className="size-10 text-white-500" />}
                </button>
                <div className="flex-1">
                    <p className="text-left font-medium mb-2 text-md">10-min Meditation for Focus</p>
                    <input
                        type="range"
                        min="0"
                        max={duration}
                        value={currentTime}
                        onChange={handleSeek}
                        className="w-full range range-xs audio-seeker"
                    />
                    <div className="flex justify-between text-xs text-gray-600">
                        <span>{formatTime(currentTime)}</span>
                        <span>{formatTime(duration)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};