export default function MeditationExamples() {
  return (
    <div class="py-24 sm:py-32">
      <div class="mx-auto max-w-4xl px-6 lg:px-8">
        <div class="mx-auto max-w-3xl lg:mx-0 text-left mb-12">
          <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Experience a meditation session on us!
          </h2>
          <p class="mt-4 text-lg leading-8">
            Unlock the power of mindfulness—try one of our guided meditation
            sessions for free today.
          </p>
        </div>
        <iframe
          title="Meditations by Sanctuary"
          allowtransparency="true"
          height="300"
          width="100%"
          className=""
          scrolling="no"
          data-name="pb-iframe-player"
          src="https://www.podbean.com/player-v2/?i=p9zp3-11a56f9-pbblog-playlist&share=1&download=1&rtl=0&fonts=Arial&skin=f6f6f6&font-color=auto&logo_link=episode_page&order=episodic&limit=10&filter=all&ss=a713390a017602015775e868a2cf26b0&btn-skin=ff6d00&size=315"
          loading="lazy"
          allowfullscreen=""
        ></iframe>
      </div>
    </div>
  );
}
